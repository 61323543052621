@use 'sass:map';
@use 'styles/variables.module.scss' as v;

.relevantWeatherThumbnailsContainer {
  padding: 0 16px;

  @media #{map.get(v.$media, "desktopS")} {
    padding: 0;
  }
}

.relevantWeatherThumbnailsHeading {
  margin-bottom: 18px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--grey2);

  @media #{map.get(v.$media, "desktopS")} {
    border: none;
  }
}

.relevantWeatherThumbnails {
  display: flex;
  justify-content: space-evenly;
  column-gap: 20px;
}

.relevantWeatherThumbnailItem {
  display: grid;
  grid-auto-columns: auto auto;
  width: 50%;
}

.relevantWeatherThumbnailItemLink {
  line-height: 0px;
  display: grid;
}

.relevantWeatherThumbnailImage {
  width: 100%;
  height: 175px;
  object-fit: fill;
  grid-area: 1 / 1;
  z-index: 2;

  &[data-isportait='true'] {
    object-fit: contain;
  }

  @media #{map.get(v.$media, "tablet")} {
    height: 250px;
  }
}

.relevantWeatherBackgroundImage {
  grid-area: 1 / 1;
  z-index: 1;
}

.transparentImage {
  opacity: 0.8;
}
